import Head from 'next/head';
import Navbar from '../organisms/navbar/Navbar';

const ErrorLayout = ({ children }) => {
    return (
        <>
            <Head>
                <meta name="Error Layout" content="to be added" />
                <title>Simfy</title>
            </Head>
            <main role="main" className={`main`}>
                {children}
                <Navbar />
            </main>
        </>
    );
};

export default ErrorLayout;
