// import Script from 'next/script';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { initFacebookPixel, trackPageview } from '../lib/fbpixel';
import { DefaultLayout as Layout } from '../components/templates';
import CookiePolicy from '../components/organisms/cookiePolicy/CookiePolicy';
import '../../assets/scss/.base/_bootstrap-reboot.min.css';
import '../../assets/scss/.base/_bootstrap-grid.min.css';
import '../../assets/scss/.base/slick.css';
import '../styles/globals.scss';

const PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL;

const App = ({ Component, pageProps }) => {
    const router = useRouter();
    useEffect(() => {
        if (typeof window !== 'undefined') {
            initFacebookPixel(PIXEL_ID);
            trackPageview();
            const handleRouteChange = () => {
                trackPageview();
            };
            router.events.on('routeChangeComplete', handleRouteChange);
            return () => {
                router.events.off('routeChangeComplete', handleRouteChange);
            };
        }
    }, [router.events]);

    return (
        <Layout {...pageProps.data}>
            <Component {...pageProps} />
            <CookiePolicy />
        </Layout>
    );
};

export default App;
