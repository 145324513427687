import Image from 'next/image';
import classes from './Header.module.scss';

export default function Header() {
    return (
        <div className={classes.oHeader}>
            <div className={`${classes.oContainer} container`}>
                <div className={`${classes.oRow} row`}>
                    <div className={`${classes.oCol} col`}>
                        <Image
                            src="/images/ayobaLogoFull.webp"
                            width="217"
                            height="64"
                            alt="Logo"
                            className={`${classes.aSiteLogo} aSiteLogo`}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
