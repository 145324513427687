import { useState, useEffect } from 'react';
import classes from './CookiePolicy.module.scss';
import Link from 'next/link';

const CookiePolicy = () => {
    const [accepted, setAccepted] = useState(true);

    useEffect(() => {
        const isAccepted = localStorage.getItem('cookiePolicyAccepted');
        if (isAccepted) {
            setAccepted(true);
        } else {
            setAccepted(false);
        }
    }, []);

    const acceptPolicy = () => {
        localStorage.setItem('cookiePolicyAccepted', 'true');
        setAccepted(true);
    };

    if (accepted) {
        return null;
    }
    return (
        <div className={classes.oCookiePolicy}>
            <p className={classes.aText}>
                We value your privacy and use cookies to enhance your browsing experience, serve
                advertisements and analyse our traffic. By clicking &nbsp;
                <span className={classes.aTextLink} onClick={acceptPolicy}>
                    Accept and Continue
                </span>
                &nbsp; you consent to our use of cookies.
            </p>
            <Link
                href={`https://ayoba.me/web/c/ayoba-cookie-policy`}
                target="_blank"
                className={`${classes.aTextLink} ${classes.centered} fnt12s`}
            >
                View Cookie Policy here
            </Link>
        </div>
    );
};

export default CookiePolicy;
