import { useState, useEffect } from 'react';
import classes from './DefaultLayout.module.scss';
import Head from 'next/head';
import Script from 'next/script';
import Navbar from '../organisms/navbar/Navbar';
import attributes from '../../pages/api/localData/DefaultLayout.json';
import { GoogleAnalytics } from '@next/third-parties/google';
import { configureApiKey } from '../../utils/netmera';
import Image from 'next/image';
import Header from '../organisms/header/Header';

/**
 * Default layout
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DefaultLayout = (props) => {
    const { children } = props;
    const [analyticsKey, setAnalyticsKey] = useState('');

    useEffect(() => {
        setAnalyticsKey(configureApiKey(location.origin));
    }, []);

    const netmeraUrl = `https://cdn.netmera-web.com/wsdkjs/${analyticsKey}`;
    const PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL;

    return (
        <>
            <Head>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '${PIXEL_ID}'); 
                            fbq('track', 'PageView');
                            `
                    }}
                />
                <title>Ayoba Lite</title>
                <meta
                    name="description"
                    content="Access ayoba features without downloading the ayoba app"
                />
                <meta charSet="UTF-8" />
                <meta
                    name="viewport"
                    content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
                />
                <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black" />
                <meta name="apple-mobile-web-app-title" content="Ayoba Lite" />
                <link
                    rel="apple-touch-icon"
                    href="/src/images/apple-icon-114x114.png"
                    sizes="114x114"
                />
                <meta name="msapplication-TileImage" content="/src/images/app-icon-114x114.png" />
                <meta property="og:image" content="/src/images/apple-icon-114x114.png" />
                <link rel="manifest" href="/manifest.json"></link>
                <meta name="theme-color" content="#FFF" />
                <noscript>
                    <Image
                        alt="none"
                        height="1"
                        width="1"
                        style={{ display: 'none' }}
                        src={`https://www.facebook.com/tr?id=${PIXEL_ID}&ev=PageView&noscript=1`}
                    />
                </noscript>
            </Head>
            <Script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6193376247880661"
                crossOrigin="anonymous"
            ></Script>
            {analyticsKey && analyticsKey.length > 0 && (
                <>
                    <Script id="netmera">var netmera = netmera || [];</Script>
                    <Script src={netmeraUrl} strategy="afterInteractive" async />
                </>
            )}
            <main role="main" className={`${classes.main} main`}>
                <Header />
                {children}
                <Navbar {...attributes} />
            </main>
            <GoogleAnalytics gaId={`${process.env.NEXT_PUBLIC_GA_TRACKING}`} />
        </>
    );
};

export default DefaultLayout;
