import moment from 'moment-timezone';

/**
 * Netmera Custom Event Trigger as per:
 * https://developer.netmera.com/en/web/events
 *
 * @param eventType
 */
export const fireEvent = (eventType) => {
    window.netmera.sendEvent({
        code: 'wyvhf',
        ea: eventMap[eventType],
        eb: getTimeZoneCountry()
    });
};

/**
 * Event Types
 *
 * @type {{appDownloadGooglePlay: string, appDownloadAppStore: string, appDownloadApk: string}}
 */
const eventMap = {
    // appDownload: 'Download App',
    // appAddToHomeScreen: 'Add To Ayoba Lite Home Screen',
    appDownloadApk: 'APK Direct Download',
    appDownloadAppStore: 'iOS App Store',
    appDownloadGooglePlay: 'Google Play Store'
};

/**
 * Moment library used to work out timezone and country of user
 *
 * @returns {country}
 */
export const getTimeZoneCountry = () => {
    const { countries, zones } = require('moment-timezone/data/meta/latest.json');

    const timezone = moment.tz.guess();
    const timeZoneToCountry = {};

    Object.keys(zones).forEach((zone) => {
        timeZoneToCountry[zone] = countries[zones[zone].countries[0]].name;
    });

    return timeZoneToCountry[timezone];
};

/**
 * Function to configure API key as per environment
 *
 * @param domain
 * @returns {string}
 */

export const configureApiKey = (domain) => {
    if (domain === 'https://web.ayoba.me' || domain === 'web.ayoba.me') return process.env.NETMERA_PROD_API_KEY;
    else return process.env.NETMERA_DEV_API_KEY;
};

